import { getComponentRegistryConfig, useTranslations } from '@b2x/core/bundle';

import type { Profile } from '@b2x/profile-library';
import type { HeaderNavigationItemsConfiguration } from '@dh-io-hp/header-connection';
import type { ComponentsState } from '@owc/component-registry-connection';

interface UserMenuComponentConfig extends ComponentsState {
  payload?: {
    loginLabel?: string;
    userProfileAria?: string;
    isProfileSelectionEnabled?: boolean;
  };
}

export const getUserMenuConfig = async () => {
  const [userMenuConfig] = await getComponentRegistryConfig<Partial<UserMenuComponentConfig[]>>((components) => {
    const registry = components.find((component) => component?.payload?.userProfileAria);
    return registry ? [registry] : [];
  });

  return {
    loginLabel: userMenuConfig?.payload?.loginLabel || 'Login',
    isProfileSelectionEnabled: Boolean(userMenuConfig?.payload?.isProfileSelectionEnabled),
  };
};

export const getNavItemConfig = (
  activeProfile: Profile,
  isLoggedIn: boolean,
  menuConfig: { loginLabel: string; isProfileSelectionEnabled: boolean },
): HeaderNavigationItemsConfiguration & { subLabel: string | undefined }[] => {
  const { t } = useTranslations();

  let { loginLabel: label, isProfileSelectionEnabled } = menuConfig;

  let fullName = activeProfile.displayName ?? '';
  let subLabel = '';

  if (isLoggedIn) {
    if (activeProfile.businessType === 'B2C') {
      label = fullName;
      subLabel = t('profile.context.private.registered');
    } else if (fullName) {
      label = fullName;
      fullName = fullName.charAt(0);
      subLabel = t('profile.context.company.registered');
    } else {
      label = t('profile.context.company.registered');
    }
  }
  // Logged out
  else if (activeProfile.businessType === 'B2C') {
    subLabel = t('profile.context.private.guest');
  } else {
    subLabel = t('profile.context.company.guest');
  }

  return [
    {
      name: 'iam-user-menu-v3',
      label,
      subLabel: isProfileSelectionEnabled ? subLabel : undefined,
      avatar: {
        fullName,
        icon: activeProfile.businessType === 'B2B' ? 'company/24' : 'bds/user/24',
      },
    },
  ];
};
